<template>
<section class="content">
    <div class="container-fluid">
        <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>

        <div class="unsubssec card mt-4" v-if="update">
            <div class="row">
                <div class="col-md-1"> 
                </div> 
                <div class="col-md-10"> 
                    <div class="bluebox"> 
                        <h4>Want to change how you receive these emails? You can update your preferences or unsubscribe from this list</h4>
                    </div>
                </div> 
                <div class="col-md-1"> 
                </div> 
            </div>  
            <div class="row unsubscontsec">
                <div class="col-md-4"> 
                        <img src="/svg/waterski/unsubscribeuserimage.svg"/>
                </div>
                <div class="col-md-8"> 
                    <p style="color: red;">{{error}}</p>
                    <div class="d-flex" v-for="(templateType,index) in templateTypes"  :key="index"> 
                            <input  type="checkbox" name="check" id="chex" v-model="form.CheckedValue[templateType.id]"  :checked="CheckedValue.includes(templateType.id)" />
                            <label for="check" class="mb-0">{{templateType.type}}</label>
                    </div>
                    <div class="">
                        <button type="button" @click="unsubscribe(CheckedValue)">Update my Preference</button> 
                        <label for="check" class="mb-0"></label>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mt-4" v-if="login">
            <div class="subsecupdatedsec"> 
                <div class="row d-flex">
                    <div class="col-md-5 d-flex"> 
                        <img src="/svg/waterski/updatedkeyimage.svg"/>
                    </div>
                    <div class="col-md-7 d-flex"> 
                        <div> 
                            <h2>Your preference has been updated</h2>
                            <p>If you want to change your email preferences</p>
                            <p>Click here to   <router-link to="/member/login"><a>Login</a></router-link></p>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

    </div>
</section>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import CustomAlert from "../../../CustomAlert.vue";
export default {
    props: ['id'],
    components:{
    CustomAlert
  }
    ,data(){
        return{
            templateTypes:"",
            emailDatas:"",
            CheckedValue:[],
            arr1:[],
            unsubform:'',
            form:{CheckedValue:[],member_id:null,request:null},
            login:false,
            update:true,
            error:''
        }
    },
    methods:{
        templateTypeList() {
            axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/templateType/getTemplateType',
                {}, {
                    headers: {
                    "application-id": process.env.VUE_APP_APPLICATION_ID,
                    'Content-Type': 'application/json'
                    }
                }).then(response => {

                if (response.data.status == 'Success') {
                this.templateTypes = response.data.templateType;
                console.log(this.templateType);
                } else {
                console.log(response);
                }
            }).catch(error => {
                this.errors = error.response.data.errors || {};

            });
        },
        getEmailData(){
            axios.post(process.env.VUE_APP_EMAIL_CAMPAIGN_URL + 'api/getEmailData',
                this.$route.params.id, {
                    headers: {
                    "application-id": process.env.VUE_APP_APPLICATION_ID,
                    'Content-Type': 'application/json'
                    }
                }).then(response => {

                if (response.data.status == 'Success') {
                    this.emailDatas = response.data.emailData;
                    this.form.member_id = response.data.emailData.member_id;
                }
            }).catch(error => {
                console.log(error)
            });
        },
        unsubscribe(e){
            console.log(this.form.CheckedValue.length > 0)
            if((this.form.CheckedValue.length > 0) === false){
                this.error = 'Please select atleast one checkbox';
                return false;
            }
           
            console.log(this.form);
            axios.post(this.basePath+'api/campaignUnsubscribe',this.form, { headers:this.header })
            .then(response => {
                    this.login = true;
                    this.update = false;
                    this.alertMessage = "Unsubscribe Update Successfully";
                    this.displayAlert = true;
             })
            .catch(error => {
                console.log(error);
                this.alertMessage = "Unsubscribe Update Failed";
                this.displayAlert = true;
            }); 
        },
        resetAlert(){
             this.displayAlert = false;
            this.removeDangerBg();
        }

    },
    mounted(){
        this.getEmailData();
        this.templateTypeList();
    }
}
</script>
